<template>
  <div>
    <div v-if="loading" style="padding: 20px">Loading...</div>
    <div v-else-if="!person">Person not found</div>
    <div v-else>
      <!-- "tree-like" -->
      <div>
        <!-- parents -->
        <h3>Padres de {{ person.name }}</h3>
        <div>
          <div style="display: flex; overflow-x: scroll">
            <div
              v-for="(rel, i) in person.relatives?.filter((x) => x.type == 'parent')"
              :key="i"
              style="margin-right: 10px"
            >
              <PersonCard :id="rel.id" :relation="rel.type" />
            </div>
            <div class="add-more">
              <v-btn
                :loading="loading_add_button == 'parent'"
                icon
                flat
                @click="addPerson('parent')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <!-- self -->
        <h3>{{ person.name }}</h3>
        <div>
          <div style="display: flex; overflow-x: scroll">
            <!-- self -->
            <PersonCard
              :person_data="person"
              :subtitle="''"
              style="border: 3px solid #141414; margin-right: 5px"
              @click.prevent="$router.push('/v2/person/' + id)"
            />
            <!-- spouse(s)-->
            <div
              v-for="(rel, i) in person.relatives?.filter((x) => x.type == 'spouse')"
              :key="i"
              style="margin-right: 10px"
            >
              <PersonCard :id="rel.id" :subtitle="rel.type + ' since ' + rel.date" />
            </div>

            <div class="add-more">
              <v-btn
                :loading="loading_add_button == 'spouse'"
                icon
                flat
                @click="addPerson('spouse')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <h3>Hijos de {{ person.name }}</h3>
        <div>
          <div style="display: flex; overflow-x: scroll; justify-content: flex-start">
            <div
              v-for="(rel, i) in person.relatives?.filter((x) => x.type == 'child')"
              :key="i"
              style="margin-right: 10px"
            >
              <PersonCard :id="rel.id" :relation="rel.type" />
            </div>
            <div class="add-more">
              <v-btn :loading="loading_add_button == 'child'" icon flat @click="addPerson('child')">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <!-- bottom sheet -->
      <v-bottom-sheet v-model="bottom_sheet">
        <div
          style="
            min-height: 500px;
            padding: 20px;
            border-radius: 15px 15px 0 0;
            background-color: white;
            position: relative;
            overflow-y: auto;
          "
        >
          <div style="text-align: right; position: absolute; top: 5px; right: 10px">
            <v-btn icon flat size="s" @click="bottom_sheet = false">
              <v-icon size="s">mdi-close</v-icon>
            </v-btn>
          </div>

          <PersonView :id="id" />
        </div>
      </v-bottom-sheet>

      <div v-if="false" style="margin-top: 20px">
        <v-btn @click="addPerson()" flat color="secondary">Añadir persona</v-btn>
      </div>
      <div v-if="false" style="margin-top: 200px; font-family: monospace; overflow-x: auto">
        {{ person }}
      </div>
    </div>
  </div>
</template>

<script>
import PersonCard from '@/views/v2/components/PersonCard.vue'
import PersonView from './PersonView.vue'

const { v4: uuidv4 } = require('uuid')

export default {
  components: {
    PersonCard,
    PersonView,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bottom_sheet: false,
      loading: false,
      loading_add_button: 'none', //parent/child/spouse, to make one of those buttons load.
      person: {},
    }
  },
  watch: {
    id() {
      this.fetchData()
    },
  },

  methods: {
    async addPerson(type) {
      this.loading_add_button = type
      console.log('type', type)

      const new_id = uuidv4()
      let draft = { id: new_id, relatives: [], lastname: [''] }

      //add parent: will have current as child, other parent as spouse, and same children as the other parent
      if (type == 'parent') {
        //fetch the children of the current parents.
        let current_parents = this.person.relatives.filter((x) => x.type == 'parent')
        let current_parents_data = []

        //in general... we expect one parent, but to keep it clean
        for (let i = 0; i < current_parents.length; i++) {
          const parent_id = current_parents[i].id
          draft.relatives.push({ type: 'spouse', id: parent_id })

          let parent_data = await this.fetchPerson(parent_id)
          current_parents_data.push(parent_data)

          let parent_children = parent_data.relatives.filter((p) => p.type == 'child')
          for (let j = 0; j < parent_children.length; j++) {
            const child = parent_children[j]
            if (draft.relatives.findIndex((x) => x.id == child.id) === -1) {
              //make sure we don't add twice
              draft.relatives.push({ type: 'child', id: child.id })
            }
          }
        }

        //guess gender:
        if (current_parents_data.length == 1) {
          if (current_parents_data[0].gender == 'male') draft.gender = 'female'
          if (current_parents_data[0].gender == 'female') draft.gender = 'male'
        }

        //guess first last name
        if (draft.gender == 'male' && this.person.lastname.length > 0) {
          draft.lastname = this.person.lastname.filter((_, i) => i % 2 === 0)
        } else if (draft.gender == 'female' && this.person.lastname.length > 1) {
          draft.lastname = this.person.lastname.filter((_, i) => i % 2 !== 0)
        }

        //add current person as child, unless already done
        if (draft.relatives.findIndex((x) => x.id == this.person.id) === -1) {
          draft.relatives.push({ type: 'child', id: this.person.id })
        }
      }

      //add child: by default this child has current person and his/her spouse as parents
      if (type == 'child') {
        let parents = this.person.relatives
          .filter((x) => x.type == 'spouse')
          .map((y) => {
            return { type: 'parent', id: y.id }
          })
        parents.push({ type: 'parent', id: this.person.id })
        draft.relatives = [...draft.relatives, ...parents]

        //add last names (for simple family with no multiple marriages)

        let parents_data = []
        for (let p = 0; p < parents.length; p++) {
          const parent_id = parents[p].id
          let parent_data = await this.fetchPerson(parent_id)
          parents_data.push(parent_data)
        }
        if (parents_data.length <= 2 && parents_data.length > 0) {
          draft.lastname = []
          let father = parents_data.find((x) => x.gender == 'male')
          let mother = parents_data.find((x) => x.gender == 'female')

          const fn = father?.lastname?.length || 0
          const mn = mother?.lastname?.length || 0
          let n = Math.max(fn, mn)
          console.log('ffn', fn, mn, n)
          for (let i = 0; i < n; i++) {
            if (i < fn) {
              draft.lastname.push(father.lastname[i])
            } else {
              draft.lastname.push('')
            }

            if (i < mn) {
              draft.lastname.push(mother.lastname[i])
            } else {
              draft.lastname.push('')
            }
          }
        }
      }

      //Add spouse: By default will have the same kids as current, as well as current as spouse
      if (type == 'spouse') {
        draft.relatives = this.person.relatives.filter((x) => x.type == 'child')
        draft.relatives.push({ type: 'spouse', id: this.person.id })
      }
      this.loading_add_button = 'none'
      this.$router.push(`/v2/edit/${new_id}?draft=${JSON.stringify(draft)}`)
    },
    async fetchPerson(id) {
      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?id=${id}`
      let x = await fetch(url, {
        method: 'GET',
        headers: { Authorization: localStorage.token },
      })

      if (x.ok) {
        return await x.json()
      } else {
        return null
      }
    },
    async fetchData() {
      this.loading = true
      let x = await this.fetchPerson(this.id)
      this.loading = false
      if (x) {
        this.person = { ...x }
      } else {
        this.person = { id: this.id, name: '', lastname: [''], relatives: [] }
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style scoped>
.add-more {
  margin: auto 5px;
  padding-right: 20px;
  border: 1px solid #ccccccb1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 200px;
}
</style>
