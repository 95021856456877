<template>
  <div>
    <div class="person">
      <div v-if="person">
        selected: {{ selected }}
        <div
          style="display: flex; justify-content: space-around; text-align: center; font-size: 12px"
        >
          <div>
            <v-avatar size="80" color="secondary">
              <v-img v-if="person.avatarUrl" :src="person.avatarUrl"></v-img>
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar>
            <div>{{ person.name }} {{ person.lastname?.[0] || '' }}</div>
          </div>

          <div v-if="spouses && spouses.length">
            <div v-for="spouse in spouses" :key="spouse.id">
              <v-avatar size="80" color="secondary">
                <v-img v-if="spouse.avatarUrl" :src="spouse.avatarUrl"></v-img>
                <v-icon v-else>mdi-account</v-icon>
              </v-avatar>
              <div>{{ spouse.name }} {{ spouse.lastname?.[0] || '' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>no data</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person_data: {
      type: Object,
      default() {
        return {
          id: '',
          realatives: [],
          name: '',
          lastname: [],
        }
      },
    },
    spouses: {
      type: Array,
      default() {
        return []
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    person_data: {
      handler(newValue) {
        this.person = { ...newValue }
      },
      deep: true,
    },
  },

  data() {
    return {
      person: null,
    }
  },
  mounted() {
    this.person = this.person_data
  },
}
</script>

<style scoped>
.person {
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 200px;
}
</style>
