<template>
  <v-app>
    <v-main class="main">
      <router-view v-slot="{ Component, route }">
        <!-- Use a custom transition or fallback to `fade` -->
        <transition :name="route.meta.transition || 'fade'">
          <component :is="Component" :key="route.path + route.params.id" />
        </transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      key: 'value',
    }
  },
  mounted() {
    let p = localStorage.pass
    if (!p) p = ''
    this.$store.commit('setPass', p) //load the password from the localStorage

    let t = localStorage.token
    if (!t) p = ''
    this.$store.commit('setToken', t)
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

body {
  background-color: rgb(242, 242, 242);
}
.main {
  margin: auto;
  padding: 1rem !important;
  width: 600px;
  background-color: rgb(242, 242, 242);
}
.v-application {
  background-color: rgb(242, 242, 242);
}

.link {
  cursor: pointer;
  color: #007bff;
}
.link:hover {
  color: #0056b3;
}
</style>
