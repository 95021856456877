<template>
  <div>
    <h2>Tree</h2>
    <div v-if="loading">Loading...</div>

    <v-btn @click="onUpClick()" flat>Up</v-btn>
    <div v-for="(row, r) in rows" :key="row.id" style="display: flex; margin-bottom: 20px">
      <div v-for="(column, c) in row" :key="column.id">
        <PersonTreeCard
          @click="onPersonClick(r, c)"
          :person_data="column.person"
          :selected="column.selected"
        />
      </div>
    </div>

    {{ rows }}

    <div v-if="false">
      <div v-for="p in cache" :key="p.id">
        <PersonTreeCard :person_data="p" :spouses="getSpouses(p)" />
      </div>
    </div>

    <div style="font-family: monospace; overflow-x: auto; margin-top: 50px">
      {{ cache }}
    </div>
  </div>
</template>

<script>
//import PersonCard from '@/views/v2/components/PersonCard.vue'
import PersonTreeCard from '@/views/v2/components/PersonTreeCard.vue'
export default {
  components: {
    //  PersonCard,
    PersonTreeCard,
  },
  props: {
    start_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      people: [],
      cache: {},
      rows: [],
    }
  },
  methods: {
    async onUpClick() {
      const upper = this.rows[0].find((x) => x.selected)
      console.log('upper', upper)
      const person = upper.person

      const parents = person.relatives.filter((x) => x.type == 'parent')
      console.log('upper peron parents', parents)
      let column = []
      for (let p = 0; p < parents.length; p++) {
        const parent_id = parents[p].id
        const parent_data = await this.fetchPerson(parent_id)

        column.push({ selected: false, person: parent_data })
      }
      this.rows.unshift(column)
    },

    onPersonClick(r, c) {
      console.log('clicked on ', r, c)
      this.rows[r][c].selected = !this.rows[r][c].selected
    },
    getSpouses(person) {
      return person.relatives
        .filter((x) => x.type == 'spouse')
        .map((z) => {
          return this.cache[z.id]
        })
    },
    async letsgo() {
      let x1 = await this.fetchPerson(this.start_id)
      this.people.push(x1)

      this.rows.push([{ person: x1, selected: true }])
    },
    async fetchRelatives() {},

    /** This function fetches a person and they spouse, avoiding loops as we first check the cache */
    async fetchPerson(id) {
      if (this.cache[id]) {
        return this.cache[id]
      }
      this.loading = true
      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?id=${id}`
      this.loading = false
      let x = await fetch(url, {
        method: 'GET',
        headers: { Authorization: localStorage.token },
      })
      if (x.ok) {
        let data = await x.json()
        this.cache[id] = data
        let spouses = data.relatives.filter((x) => x.type === 'spouse')
        if (spouses.length) {
          spouses.forEach((s) => {
            this.fetchPerson(s.id)
          })
        }
        return data
      } else {
        return null
      }
    },
  },
  mounted() {
    this.letsgo()
  },
}
</script>

<style scoped></style>
