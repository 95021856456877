<template>
  <div>
    <h2 v-if="!newPerson">Edit</h2>
    <h2 v-else>Nueva persona</h2>

    <!-- upload image -->
    <div>
      <AvatarWithEditor :person="person" />
    </div>

    <v-text-field v-model="person.name" label="nombre"></v-text-field>

    <div v-for="(ln, i) in person.lastname" :key="i">
      <v-text-field
        v-model="person.lastname[i]"
        :label="'Apellido ' + (i + 1)"
        hide-details
        class="mb-2"
        clearable
        @click:clear="person.lastname[i] = ''"
      ></v-text-field>
    </div>
    <div style="margin: 15px">
      <span class="link" @click="addLastName()">Añadir apellido</span>
    </div>

    <!-- birthday -->
    <v-text-field
      label="Fecha de nacimiento"
      placeholder="YYYY-MM-DD"
      v-model="person.birthday"
      @keypress="birthdayCheck"
      @keyup="birthdayKeyUp"
    ></v-text-field>

    <!-- passed away date -->
    <v-text-field
      label="Fecha de fallecimiento"
      placeholder="YYYY-MM-DD"
      v-model="person.deathdate"
    ></v-text-field>

    <v-text-field v-model="person.nickname" label="Nombre de pila" clearable></v-text-field>
    <v-select :items="options.genders" v-model="person.gender" label="Sexo"></v-select>
    <!-- hijos -->
    <div style="margin-top: 10px">
      <h3>Familia</h3>
      <div style="display: flex; overflow-x: auto; margin-bottom: 10px">
        <div v-for="(rel, i) in person.relatives" :key="i">
          <div style="position: relative; margin-right: 5px">
            <PersonCard :id="rel.id" :subtitle="rel.type" />
            <div style="position: absolute; top: 4px; right: 4px">
              <v-icon @click="removeRelation(i)">mdi-close</v-icon>
            </div>
          </div>
        </div>
      </div>
      <!-- more family-->
      <h3>Añadir más familia</h3>
      <div>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="newRelative.id" label="URL de la persona"></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="newRelative.type" :items="options.relations"></v-select>
          </v-col>
        </v-row>
        <v-btn flat color="primary" @click="pushRelative()">Añadir</v-btn>
      </div>
    </div>

    <v-row>
      <v-col>
        <v-btn @click="cancel()" flat color="error" block style="margin-top: 20px">cancelar</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="saveIt()" flat color="primary" block style="margin-top: 20px">Guardar</v-btn>
      </v-col>
    </v-row>

    <div v-if="false">
      {{ person }}
    </div>
  </div>
</template>

<script>
/*
const { v4: uuidv4 } = require('uuid')
const uniqueId = uuidv4()
*/
import PersonCard from '@/views/v2/components/PersonCard.vue'
import AvatarWithEditor from './components/AvatarWithEditor.vue'

export default {
  components: {
    PersonCard,
    AvatarWithEditor,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  watch: {
    id() {
      this.fetchMe()
    },
    person: {
      handler() {
        //alert('changed') //this fires too fast to be used for saving.
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      newPerson: true,
      options: {
        relations: [
          { title: 'Padre/Madre', value: 'parent' },
          { title: 'Hijo/Hija', value: 'child' },
          { title: 'Mujer/Marido', value: 'spouse' },
        ],
        genders: [
          { title: 'Hombre', value: 'male' },
          { title: 'Mujer', value: 'female' },
        ],
      },
      newRelative: { type: '', id: '', date: '' },

      key: 'value',
      person: {
        name: '',
        lastname: [''],
        relatives: [],
      },
      relations: [],
    }
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    birthdayCheck(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      console.log('char', char)
      if (/^[0-9-]+$/.test(char)) {
        return true
      }
      // Match with regex
      else {
        e.preventDefault() // If not match, don't add to input text
      }
    },
    addLastName() {
      if (!this.person.lastname) this.person.lastname = []
      this.person.lastname.push('')
    },
    removeRelation(index) {
      this.person.relatives.splice(index, 1)
    },

    async fetchMe() {
      this.loading = true
      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?id=${this.id}`
      let x = await fetch(url, {
        method: 'GET',
        headers: { Authorization: localStorage.token },
      })
      if (x.ok) {
        this.newPerson = false
        this.person = await x.json()
      } else {
        this.newPerson = true
        this.person = { id: this.id, name: '', lastname: [''], relatives: [] }
      }
      this.loading = false
    },
    pushRelative() {
      if (!this.person.relatives) this.person.relatives = []
      let newRelative = { ...this.newRelative }

      if (newRelative.id.length != 36) {
        const regex = /\/person\/([a-f0-9-]+)/i
        const match = newRelative.id.match(regex)
        newRelative.id = match ? match[1] : newRelative.id
      }
      this.person.relatives.push(newRelative)

      this.newRelative = { id: '', relation: '' }
    },
    async saveIt() {
      const token = localStorage.token
      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?token=${token}`
      let x = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(this.person),
        //   headers: { Auhtentication: token },
      })
      if (x.ok) {
        alert('Success!')
        this.$router.back()
      } else alert('Ouch. Error...')
    },
  },
  mounted() {
    window.scroll(0, 0)
    let draft = this.$route.query.draft
    console.log('draft11', draft)
    if (draft) {
      this.person = JSON.parse(draft)
    } else {
      this.fetchMe()
    }
  },
}
</script>

<style lang="scss" scoped></style>
