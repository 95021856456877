<template>
  <div>
    <AvatarEditor @onChanged="onImageChange" :initialImage="person.avatarUrl || ''" />

    <div v-if="loading" style="margin: auto; text-align: center">
      <v-progress-circular indeterminate style="z-index: 99999999"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import AvatarEditor from './AvatarEditor.vue'
export default {
  components: {
    AvatarEditor,
  },
  data() {
    return {
      key: 'value',
      loading: false,
    }
  },
  props: {
    person: {
      type: Object,
      default: () => {
        return { avatarUrl: '' }
      },
    },
  },
  methods: {
    async onImageChange(e) {
      this.loading = true
      console.log('image change', e)
      let image_data = { ...e }
      const extension = image_data.filename.split('.').pop() // Extract the extension
      image_data.base64 = image_data.base64.split(',')[1]
      image_data.filename = this.person.id + '.' + extension
      image_data.id = this.person.id

      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/avatar?id=${this.person.id}`
      let x = await fetch(url, {
        method: 'POST',
        headers: { Authorization: localStorage.token },
        body: JSON.stringify(image_data),
      })
      if (x.ok) {
        const j = await x.json()
        console.log('image uploaded', j)
      } else {
        const t = await x.text()
        alert('Error uploading image\n' + t)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
