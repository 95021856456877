//import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import EditView from '@/views/v2/EditVue.vue'
import AuthView from '@/views/AuthView.vue'
import AuthView2 from '@/views/v2/AuthView.vue'
import TreeView from '@/views/v2/TreeView.vue'
import TreePersonView from '@/views/v2/TreePersonView.vue'
import PersonView from '@/views/v2/PersonView.vue'
import HomeView2 from '@/views/v2/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true,
    },
    component: HomeView,
  },
  {
    path: '/v2/person/:id',
    name: 'PersonView',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: PersonView,
  },
  {
    path: '/v2/edit/:id',
    name: 'EditView',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: EditView,
  },
  {
    path: '/v2',
    name: 'HomeView2',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: HomeView2,
  },
  {
    path: '/v2/tree2/:start_id',
    name: 'TreeView',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: TreeView,
  },
  {
    path: '/v2/tree/:id',
    name: 'TreePersonView',
    props: true,
    meta: {
      requiresAuth: true,
      transition: 'slide',
    },
    component: TreePersonView,
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/v2/auth',
    name: 'auth2',
    component: AuthView2,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const pass = localStorage.pass
    if (pass != 'abuelita') next('/auth')
    else next()
  } else next()
})

export default router
