<template>
  <div>
    <h1>Family Tree</h1>
    <div style="margin-top: 10px">
      <v-text-field v-model="token" :type="fieldtype" label="token" hide-details> </v-text-field>
      <div style="cursor: pointer; color: blue; font-size: 80%; text-align: right; margin-top: 4px">
        <span v-if="fieldtype == 'password'" @click="fieldtype = 'text'">show token</span>
        <span v-else @click="fieldtype = 'password'">hide token</span>
      </div>
    </div>
    <div style="margin-top: 20px">
      <v-btn :to="'/v2/tree/1'" flat color="secondary">Start somewhere</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    token(newValue) {
      localStorage.token = newValue
    },
  },
  data() {
    return {
      fieldtype: 'password',
      token: '',
    }
  },
  mounted() {
    this.token = localStorage.token || ''
  },
}
</script>

<style lang="scss" scoped></style>
