<template>
  <div class="person-card" @click="goToMe">
    <div v-if="error">
      {{ error }}
    </div>
    <div v-else>
      <div>
        <div>
          <v-avatar size="80" color="secondary">
            <v-img v-if="person.avatarUrl" :src="person.avatarUrl"></v-img>
            <v-icon v-else>mdi-account</v-icon>
          </v-avatar>
        </div>
        <div style="font-size: 80%; margin-top: 5px">
          <div>{{ person.name }} {{ person.lastname.slice(0, 2).join(' ') }}</div>
          <div v-if="subtitle" style="color: grey">{{ subtitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    person_data: {
      type: Object,
      default() {
        return {
          id: '',
          realatives: [],
          name: '',
          lastname: [],
        }
      },
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  watch: {
    id() {
      this.fetchData()
    },
    person_data: {
      handler(newValue) {
        this.person = { ...newValue }
      },
      deep: true,
    },
  },
  computed: {
    final_id() {
      return this.id ? this.id : this.person_data.id
    },
  },
  data() {
    return {
      error: '',
      person: { id: '', lastname: [] },
    }
  },

  methods: {
    goToMe() {
      this.$router.push('/v2/tree/' + this.final_id)
    },
    async fetchData() {
      const id = this.id
      const token = localStorage.token // this.$store.token
      console.log('id', id)
      console.log('tokenn', token)

      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?id=${this.final_id}` //&token=${token}` //&token=`
      let x = await fetch(url, {
        method: 'GET',
        headers: { Authorization: token },
      })
      if (x.ok) {
        const j = await x.json()
        if (!j.lastname) j.lastname = []
        this.person = j
      } else {
        this.error = await x.text()
      }
    },
  },
  mounted() {
    if (this.person_data && this.person_data.id) {
      this.person = { ...this.person_data }
    } else if (this.id) {
      this.fetchData()
    } else {
      //nothing. we will update the data once one of the props changes (via watcher)
    }

    // console.log('token', this.$store.token)
  },
}
</script>

<style scoped>
.person-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 5px;
  margin: 8px 0px;
  cursor: pointer;
  width: 150px;
  height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.person-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 150, 255, 0.4); /* Adding a glow effect */
}
</style>
